import axios from 'axios'
import store from "@/store/index";
import router from '../routers';

export default {
    async SetReport({ commit }, report) {
        commit("setReport", report);
    },

    async LogOut({ commit }) {
        commit("logout", null);
    },

    async LogIn({ commit }, User) {
        await axios.post('user/login', User)
            .then(function (response) {
                commit('setUser', { ...response.data.data.user_info, token: response.data.data.token })
            })
            .catch(error => {
                console.error(error);
                throw error
            });
    },

    async GetProtocols({ commit }) {
        let user = store.state.user
        await axios.post('pep/update/queue', null, {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {
                commit("setProtocols", response.data.data.protocol_updates_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetReportsUnsigned({ commit }) {
        let user = store.state.user
        await axios.post('pep/report/specialist',
            {
                signed: false,
                finished: true
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReportsUnsigned", response.data.data.reports_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetReports({ commit }) {
        let user = store.state.user
        await axios.post('pep/report/specialist',
            {
                finished: false
            }, {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {
                commit("setReports", response.data.data.reports_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetSpecialistActives({ commit }) {
        let user = store.state.user
        await axios.post('/health_professional/list',
            {
                enable: 1
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setSpecialistsActives", response.data.data.health_professionals_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async SetProtocol({ commit }, protocolUpdate) {
        let user = store.state.user
        await axios.post('/pep/report/create',
            {
                protocol_update_id: protocolUpdate.id
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReport", response.data.data.report_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                }
                else if (error.response && error.response.status === 400) {
                    if (error.response.error.code === 422) {
                        throw new Error('422');
                    }
                    else {
                        console.error(error);
                        throw error
                    }
                }
                else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetReportById({ commit }, id) {
        let user = store.state.user
        if (id != null) {
            await axios.post('/pep/report/get',
                {
                    report_id: id
                },
                {
                    headers: { Authorization: `Bearer ${user.token}` }
                })
                .then(response => {
                    commit("setReport", response.data.data.report_info);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        commit("logout", null);
                        router.push('/login');
                    } else {
                        console.error(error);
                        throw error
                    }
                });
        }
    },

    async SendImage({ commit }, canvas) {
        try {
            let user = store.state.user
            const formData = new FormData();

            await new Promise((resolve) => {
                canvas.toBlob(blob => {
                    formData.append('photo', blob, 'image.jpeg');
                    resolve();
                }, 'image/jpeg');
            });

            const response = await axios.post('/user/update_photo', formData, {
                headers: { Authorization: `Bearer ${user.token}` }
            });
            commit('setUser', { ...response.data.data.user_info, token: user.token });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                commit("logout", null);
                router.push('/login');
            } else {
                console.error(error);
                throw error;
            }
        }
    },

    async GetReport({ commit }, report) {
        let user = store.state.user
        if (report != null) {
            await axios.post('/pep/report/get',
                {
                    report_id: report.id
                },
                {
                    headers: { Authorization: `Bearer ${user.token}` }
                })
                .then(response => {
                    commit("setReport", response.data.data.report_info);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        commit("logout", null);
                        router.push('/login');
                    } else {
                        console.error(error);
                        throw error
                    }
                });
        }
    },

    async DeleteReport({ commit }, report) {
        let user = store.state.user
        await axios.post('/pep/report/delete',
            {
                report_id: report.id,
                reason: report.reason
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(function () {
                commit("setReport", null);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetExams({ commit }) {
        let user = store.state.user
        await axios.get('resource/imaging_exam', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {
                commit("setExams", response.data.data.imaging_exams_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetDiagnosticHypothesis({ commit }) {
        let user = store.state.user
        await axios.get('resource/diagnostic_hypotheses', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {
                commit("setDiagnosticHypothesis", response.data.data.diagnostic_hypothesis_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async UpdateReport({ commit }, report) {
        let user = store.state.user
        await axios.post('/pep/report/update',
            {
                report_id: report.id,
                report_info: report.info
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReport", response.data.data.report_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async ChangeChat({ commit }, params) {
        let user = store.state.user
        await axios.post('/pep/report/change-chat',
            {
                report_id: params.report.id,
                chat_type: params.chat_type
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReport", response.data.data.report_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async SendPin({ commit }, pin) {
        let user = store.state.user
        await axios.post('/user_cess_access/create',
            {
                password: pin
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit('setUser', { ...response.data.data.user_info, token: user.token })
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                }
                else if (error.response && error.response.status === 400) {
                    throw new Error('400');
                }
                else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetOutcomes({ commit }) {
        let user = store.state.user
        await axios.get('resource/outcome', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {
                commit("setOutcomes", response.data.data.outcomes_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async SetEnable({ commit }, enable) {
        let user = store.state.user
        await axios.post('/user/set_status',
            {
                enable: enable
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setEnable", response.data.data.user_info.enable === 0 ? false : true);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetTokenMemed({ commit }) {
        let user = store.state.user
        await axios.get('user/memed', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {                      
                commit("setTokenMemed", response.data.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetEnable({ commit }) {
        let user = store.state.user
        await axios.get('user/show', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
            .then(response => {
                commit("setEnable", response.data.data.user_info.enable === 0 ? false : true);
                commit('setUser', { ...response.data.data.user_info, token: user.token })
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetHistorics({ commit }, params) {
        let user = store.state.user
        let resultados = null;
        await axios.post('/pep/report/list',
            params,
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                resultados = response.data.data;
            })
            .catch(error => {
                resultados = null
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
        return resultados;
    },

    async GenerateFile({ commit }, fileId) {
        let user = store.state.user
        await axios.post('/pep/file/get_url',
            {
                file_id: fileId
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setUrlFile", response.data.data.url);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GenerateReport({ commit }, report) {
        let user = store.state.user
        await axios.post('/pep/report/get_url',
            {
                report_id: report.id
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setUrlReport", response.data.data.url);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async ResignReport({ commit }, report) {
        let user = store.state.user
        await axios.post('/pep/report/resign',
            {
                report_id: report.id
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReport", response.data.data.report_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GenerateUrlReport({ commit }, report) {
        let user = store.state.user
        await axios.post('/pep/report/generate',
            {
                report_id: report.id
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReport", response.data.data.report_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async GetDataConditional({ commit }, objetoFilter) {
        let user = store.state.user
        var retorno = []
        await axios.post(objetoFilter.parameters[0],
            {
                [objetoFilter.parameters[3]]: objetoFilter.fieldValue
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                retorno = response.data.data.item.items;
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
        return retorno;
    },

    async GetReportFields({ commit }, specialty) {
        let user = store.state.user
        await axios.post('/resource/report_fields',
            {
                specialty_id: specialty
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setReportFields", response.data.data.report_fields_info);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

    async openModalSignatureRequired({ commit }) {
        commit('setModalSignatureRequired', true);
    },

    async closeModalSignatureRequired({ commit }) {
        commit('setModalSignatureRequired', false);
    }
}